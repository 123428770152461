import React, { Component } from 'react'


let initialState = {
    data: {},
    message: ""
}

let data = require('../../SAMPLE_DATA/users.json')
console.log("Data: ", data)

class Table extends Component {
    constructor(props) {
        super(props);
    }
    state = initialState
    
    
    // Some nifty things here
    componentDidMount(prevState) {
        if (prevState != this.state) {
            // Do some cool update functions here
        }
        console.log("hello, I'm here now")
        this.setState({message: "This will be a table"})
    }
    


    render() { 
        return ( <div className='tableParent'>{this.state.message}</div> );
    }
}
 
export default Table;