import logo from "./logo.svg";
import "./App.css";
import { Component } from "react";
import Table from "./components/Table/table";
import Login from "./components/Login/login";

let initialState = {
  showTable: false
} 

class App extends Component {
  state = initialState

  componentDidMount() {
    if (!this.state.AuthUser) {
      this.setState({showTable: false})
    }
  }

  AuthUser = () => {
    this.setState({showTable: true, AuthUser: true})
  }


  render() {
    return <div className="App">
      {this.state.showTable? <Table /> : <Login authFunc={this.AuthUser} />}
    </div>;
  }
}

export default App;
