import { Component } from "react";
import { Form, Button } from "react-bootstrap";

// TODO: https://react-bootstrap.github.io/forms/validation/

class Login extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <div>
        <Form className="loginParent">
          <Form.Label className="formHeader">Log In To Continue...</Form.Label>
          <br />
          <br />
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="formLabel">Email Address</Form.Label>
            <br />
            <Form.Control type="email" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="formLabel">Password</Form.Label>
            <br />
            <Form.Control type="password" />
          </Form.Group>

          <Button
            className="loginButton"
            variant="primary"
            type="submit"
            onClick={this.props.authFunc}
          >
            Enter
          </Button>
        </Form>

        <div className="attributeSection">S0FTWA.RE</div>
      </div>
    );
  }
}

export default Login;
