import { Navbar, Container, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTableList , faSignOutAlt, faUserGear, faPersonHarassing } from '@fortawesome/free-solid-svg-icons'

const NavElem = () => {
  // TODO: Read storage here to see if a user is set
  let userValue = "";

  return (
    <Navbar>
      <div className="navParent">
        <div className="logoGroup">
          <FontAwesomeIcon className="logo" icon={faPersonHarassing} />
          <div className="userInfoSection">{userValue ? userValue : "Welcome!"}</div>
        </div>
        <div className="buttonGroup">
          <Button><FontAwesomeIcon icon={faTableList} /></Button>
          <Button><FontAwesomeIcon icon={faSignOutAlt} /></Button>
        </div>
      </div>
    </Navbar>
  );
};

export default NavElem;
